import 'react';
import styled from 'styled-components';

const ColItem2 = styled.div`
  padding-right: 11.5rem;
  padding-left: 15rem;
  margin-left: 15.1875rem;
  margin-right: 20px;
`;

export default ColItem2;