import 'react';
import styled from 'styled-components';


const H2 = styled.h2`
    font-weight: 500;
    font-size: 210px;
    line-height: 200px;
`;

export default H2;