import 'react';
import styled from 'styled-components';


const H3 = styled.h3`
    font-weight: 300;
    font-size: 150px;
    line-height: 120px;
`;

export default H3;