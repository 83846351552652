import 'react';
import styled from 'styled-components';

const ColItem1 = styled.div`
  display: block;
  position: relative;
  top: 3rem;
  margin-bottom: 1rem;
  
`;

export default ColItem1;