import 'react';
import styled from 'styled-components';

const PortfolioContainer = styled.div`
  width: 64vw;
  box-sizing: border-box;
  padding-right: 15rem;
  margin-top: 2.5rem;
`;

 export default PortfolioContainer;
